<template>
    <div>
        <QScoreCard
            :agent-code="agentCode"
            :filters="filters"
            key="QScoreCardResets"
            title="Resets"
            api-endpoint="resets"
            drill-downLabel="Agent Breakdown"
            drill-down-route="Drilldown_Resets"
            card-style="production"
            format="Currency"
        >
            Note: APV is the split percentage of the resetting agent.
        </QScoreCard>
    </div>
</template>

<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardResets",
    props: ["agentCode", "filters"],
    components: {
        QScoreCard,
    },
}
</script>

<style scoped></style>
