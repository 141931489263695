<template>
    <div>
		<div style="height:4px">
			<v-progress-linear v-if="loading" indeterminate color="q_leads_4" />
		</div>
        <q-leaderboard-data-table
			:show-all="showAll"
			:title="the_title"
			:titleInfo="titleInfo"
			:subtitle="subtitle"
			:item-key="itemKey"
			:export-fields="exportFields"
			:headers-config="headersConfig"
			:rows="itemsPerPage"
			:leaderboard-data="leaderboardData"
			:agent-position="agentPositionData"
			:start-date="displayStartDate"
			:end-date="displayEndDate"
			@update:rows="itemsPerPage = $event"
		>
			<template #title="slotScope">
				<slot name="title" v-bind="slotScope" />
			</template>
		</q-leaderboard-data-table>
    </div>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QLeaderboardDataTable from './QLeaderboardDataTable.vue'

export default {
	name: 'QLeaderboardDataTableWrapper',
	components: {
        QLeaderboardDataTable,
    },
    props: {
        agentCode: {
            type: String,
        },
        type: {
            type: String,
        },
        timeFrame: {
            type: String,
        },
        title: {
            type: String,
        },
        originalTitle: {
            type: Boolean,
            default: false,
        },
        titleInfo: {
            type: String,
        },
        subtitle: {
            type: String,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
        rows: {
            type: Number,
        },
        startDate: {
            type: String,
        },
        endDate: {
            type: String,
        },
        itemKey: {
            type: String,
        },
        exportFields: {
            type: Array,
        },
        headersConfig: {
            type: Array,
        },
    },
    data () {
        return {
            leaderboardData: [],
            agentPositionData: undefined,
            loading: false,
            displayStartDate: this.startDate,
            displayEndDate: this.endDate,
			itemsPerPage: 5,
        }
    },
    mounted () {
		if (this.rows) {
			this.itemsPerPage = this.rows
		}
        this.loadData()
    },
	computed: {
		computedRows: {
			get () { return this.rows },
			set (val) { this.$emit('update:rows', val) },
		},
        the_title () {
			if (this.originalTitle) { return this.title }
			return this.title + ' ' + this.titleCase(this.timeFrame) + ' Leaderboard'
        },
    },
    methods: {
        loadData () {
            this.loading = true
            const filters = {
                startDate: this.startDate,
                endDate: this.endDate,
            }
		  	QuilityAPI.loadLeaderboard(this.timeFrame, this.type, this.agentCode, this.itemsPerPage, this.showAll, filters)
				.then((json) => {
					this.leaderboardData = json?.data || []
					this.agentPositionData = json?.meta?.agent_position
					this.displayStartDate = json?.meta?.start_date
					this.displayEndDate = json?.meta?.end_date
					this.loading = false
					this.$emit('loaded')
				}).catch(() => {
					this.loading = false
				})
        },
    },
    watch: {
		timeFrame () {
			this.loadData()
		},
		rows (val) {
			this.itemsPerPage = val
		},
		itemsPerPage () {
			this.loadData()
		},
        startDate (newV) {
            this.displayStartDate = newV
			this.loadData()
        },
        endDate (newV) {
            this.displayEndDate = newV
			this.loadData()
        },
    },
}
</script>
